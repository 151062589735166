import styled from 'styled-components'
import { fonts, colors } from '@constants/styles'

export const ProductWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  margin-top: 1.5rem;
  &:first-of-type {
    margin-top: 0;
  }
`

export const ProductList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr auto;
  align-items: center;
  width: 100%;
  padding: 1rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  background-color: ${colors.secondary};
  color: ${colors.white};

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const Small = styled.span`
  font-size: ${fonts.txtSmall};
  font-weight: 400;
  @media only screen and (max-width: 1024px) {
    margin-left: 5px;
  }
`

export const Remove = styled.span`
  font-size: ${fonts.txtSmall};
  line-height: 1;
  text-decoration: underline;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const Price = styled.div`
  color: ${colors.darkerGrey};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    border-top: 1px solid ${colors.lightGrey};
    flex-direction: row;
    padding: 14px 0;
    justify-content: center;
    align-items: baseline;
  }
`
export const CloseIcon = styled.div`
  position: absolute;
  display: none;

  top: 2px;
  right: 3px;
  &&& > button {
    background-color: ${colors.lightGrey};
    border-radius: 50%;
    > img {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

export default ProductWrapper
