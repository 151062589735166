import React, { useState } from 'react'
import { bool, func, number, object, string } from 'prop-types'
import { addDashesToNumber } from '@helpers/number-helper'
import { currencyFormatUS } from '@helpers/string-helper'
import CloseModal from '@components/shared/modals/close-modal'

import ProductListItems from '../product-list-items'
import { STORE_CART_UNREMOVABLE_SKUS } from '../constants'
import { ProductWrapper, ProductList, Small, Count, Remove, Price, CloseIcon } from './styles'
import StoreCartRemoveModal from '../store-cart-remove-modal'
import ProductPrice from '../../../../@rtg2022/components/atoms/Price/Price'

const PhoneNumber = ({ isMobile, phoneNumber }) => {
  if (isMobile) {
    return (
      <span style={{ fontWeight: 700 }}>
        <a href={`tel:${phoneNumber}`}>{addDashesToNumber(phoneNumber)}</a>
      </span>
    )
  }

  return <span style={{ fontWeight: 700 }}>{addDashesToNumber(phoneNumber)}</span>
}

PhoneNumber.propTypes = {
  isMobile: bool,
  phoneNumber: string,
}

const getProductListItems = (item, parentImageUrl = '') => {
  let productListItems
  if (item?.childItems?.length) {
    productListItems = item.childItems.map((childItem, i) => ({
      key: `${item.sku}_${childItem.sku}_${i}`,
      imageUrl: childItem?.imageUrl ?? parentImageUrl,
      quantity: item.quantity * childItem.quantity,
      sku: childItem.sku,
      title: childItem.title ?? '',
      warrantyEnabled: childItem.warrantyEnabled ?? false,
      warrantyPrice: childItem?.warrantyPrice?.price,
    }))
  } else {
    productListItems = [
      {
        key: item.sku,
        imageUrl: parentImageUrl,
        quantity: item.quantity,
        sku: item.sku,
        title: item.title ?? '',
        warrantyEnabled: item.warrantyEnabled ?? false,
        warrantyPrice: item?.warrantyPrice?.price,
      },
    ]
  }

  return productListItems
}

const StoreCartWrapper = ({ index, item, isMobile, removeStoreCartProduct, storeInfo, parentImageUrl }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleCancel = () => setOpenModal(false)
  const handleRemove = () => {
    setOpenModal(false)
    removeStoreCartProduct(item)
  }

  const cannotRemove = item.required || STORE_CART_UNREMOVABLE_SKUS.some(i => i.sku === item.sku)
  const productListItems = getProductListItems(item, parentImageUrl)

  return (
    <ProductWrapper className="product-wrapper">
      <Count>{index + 1}</Count>
      <ProductList>
        <ProductListItems
          isMobile={isMobile}
          isUnavailable={item?.isUnavailable ?? false}
          productListItems={productListItems}
        />
        <Price>
          <ProductPrice price={currencyFormatUS(item?.unitPrice)} />
          <Small>{item?.childItems?.length > 1 ? ' Room package' : null}</Small>
        </Price>
        <Remove type="button" onClick={() => setOpenModal(true)}>
          Remove
        </Remove>
        <CloseIcon>
          <CloseModal closeModal={() => setOpenModal(true)} />
        </CloseIcon>
        {cannotRemove ? (
          <StoreCartRemoveModal isOpen={openModal} onCancel={handleCancel}>
            <p style={{ margin: '0 auto', maxWidth: isMobile ? null : 320 }}>
              This product cannot be removed from your Cart online. Please contact the store associate listed below to
              remove this product from your cart.
            </p>
            <br />
            Associate: <span style={{ fontWeight: 700 }}>{storeInfo?.salesPersonInfo ?? ''}</span>
            <br />
            Phone #:{' '}
            <PhoneNumber
              isMobile={isMobile}
              phoneNumber={storeInfo?.storePhoneNumber?.replaceAll(/[^0-9]+/g, '') ?? ''}
            />
          </StoreCartRemoveModal>
        ) : (
          <StoreCartRemoveModal isOpen={openModal} onCancel={handleCancel} onRemove={handleRemove}>
            If you remove an item from your <br />
            store cart, it cannot be added back. <br />
            Would you like to remove the item?
          </StoreCartRemoveModal>
        )}
      </ProductList>
    </ProductWrapper>
  )
}

StoreCartWrapper.propTypes = {
  index: number,
  isMobile: bool,
  item: object,
  removeStoreCartProduct: func,
  storeInfo: object,
  parentImageUrl: string,
}

export default StoreCartWrapper
