import React, { useState } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { makeStyles } from '@material-ui/core'
import { styled, useTheme, Grid, Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontWeight: 500,
}))

const StyledContent = styled(DialogContent)({
  padding: 0,
})

const ContentGrid = styled(Grid)({
  margin: '0 auto',
  fontSize: 14,
})

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '12rem',
  fontWeight: 600,
  marginBottom: '-15px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

const StyledDivider = styled(`hr`)({
  width: '100%',
  borderTop: '1px solid #DEDEDE',
})

const ViewMyStoreCartModal = ({
  associateName,
  storeCartItems,
  onlineCartItems,
  open,
  onCombineStoreAndOnlineCarts,
  onClearOnlineCart,
}) => {
  const theme = useTheme()

  const Associate = () => <span style={{ textTransform: 'capitalize' }}>{associateName.toLowerCase()}</span>

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      sx={{
        '.MuiPaper-root': {
          padding: '20px',
        },
      }}
      disableEscapeKeyDown
    >
      <Title variant="h5" textTransform="capitalize" color={theme.palette.primary.dark}>
        {`${storeCartItems} Item${storeCartItems > 1 ? 's' : ''} Added To Your Store Cart By Store Associate,`}{' '}
        <Associate />
      </Title>
      <StyledContent>
        <StyledDivider />
        <Grid container>
          <Grid item xs={12} display="flex">
            <WarningAmberIcon color="error" />
            <Typography
              color="error"
              sx={{
                marginLeft: '0.5rem',
                fontSize: {
                  xs: '14px',
                  md: '16px',
                },
              }}
            >
              {`You have ${onlineCartItems > 1 ? 'other items' : 'another item'} in your online shopping cart.`}
            </Typography>
          </Grid>
        </Grid>

        <ContentGrid container item xs={12} sm={11}>
          <Typography
            color={theme.palette.primary.dark}
            padding="16px 0 24px 0"
            sx={{
              fontSize: {
                xs: '14px',
                md: '16px',
              },
            }}
          >
            Would you like to continue with only items added to your cart by <Associate /> or combine with items
            previously in your online shopping cart?
          </Typography>
        </ContentGrid>
      </StyledContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '8px 0' }}>
        <ContentGrid container item spacing={1} xs={12} sm={11}>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={onCombineStoreAndOnlineCarts}
              variant="outlined"
              color="primary"
              label="StoreAndOnline"
              size="large"
              fullWidth
              sx={{ fontWeight: 600 }}
            >
              Store And Online Cart
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledButton onClick={onClearOnlineCart} variant="outlined" label="StoreOnly" size="large" fullWidth>
              Store Cart Only
            </StyledButton>
          </Grid>
        </ContentGrid>
      </DialogActions>
    </Dialog>
  )
}

ViewMyStoreCartModal.propTypes = {
  associateName: PropTypes.string,
  open: PropTypes.bool,
  storeCartItems: PropTypes.number,
  onlineCartItems: PropTypes.number,
  onCombineStoreAndOnlineCarts: PropTypes.func,
  onClearOnlineCart: PropTypes.func,
}

export default ViewMyStoreCartModal
