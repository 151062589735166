import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import PaymentSvg from '@shared/svgs/paymentSvgComp'

const cards = [
  'discover',
  'mastercard',
  'visa',
  'amex',
  'rtg',
  'paypal',
  'acima',
  'klarna',
  'affirm',
  'bitpay',
  'genesis',
]

const PaymentMethods = ({ cart, uniqueNameForId, width }) => (
  <Stack sx={{ padding: '0 1rem', width: '100%' }}>
    <h4 className="bold">We Accept</h4>
    <PaymentSvg cart={cart} uniqueNameForId={uniqueNameForId} width={width} cards={cards} />
  </Stack>
)

PaymentMethods.propTypes = {
  width: PropTypes.string,
  uniqueNameForId: PropTypes.string,
  cart: PropTypes.object,
}

export default PaymentMethods
