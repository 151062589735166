/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import { verifyAndUpdateCart, cartUpdate, getPromoTargetSkus, loadCartFromOrder } from '@helpers/cart'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { getLastParamOfPath } from '@helpers/string-helper'
import AllCartsWrapper from './all-carts-wrapper'

let apiCalled = false

export const Carts = () => {
  const { isMobile } = useSelector(state => state.global, shallowEqual)
  const { cart, storeCartCreationData } = useSelector(state => state.cart, shallowEqual)
  const { order } = useSelector(state => state.checkout, shallowEqual)

  const [skusNotAvailable, setSkusNotAvailable] = useState([])
  const [discount, setDiscount] = useState(null)
  const [showPayments, setShowPayments] = useState({})
  const [storeCartIsLoading, setStoreCartIsLoading] = useState(true)
  const promoTargetSkus = getPromoTargetSkus()

  const setApiCalled = called => {
    apiCalled = called
  }

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'cart', title: 'Cart', path: '/cart' } })
    const lastParam = getLastParamOfPath()
    const pattern = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}')
    if (lastParam && pattern.test(lastParam)) {
      loadCartFromOrder(lastParam)
    }
  }, [])

  useEffect(() => {
    if (!_isEmpty(cart?.cartItems) || storeCartCreationData) {
      verifyAndUpdateCart(cart)
      cartUpdate({
        apiCalled,
        cart,
        checkProductAvailability: true,
        setApiCalled,
        setDiscount,
        setShowPayments,
        setSkusNotAvailable,
        setStoreCartIsLoading,
        skusNotAvailableIn: skusNotAvailable,
        storeCartCreationData,
        updateOrder: true,
        updateCartIfSwappable: true,
      })
    } else {
      setStoreCartIsLoading(false)
    }
  }, [JSON.stringify(cart), storeCartCreationData])

  return (
    <AllCartsWrapper
      cart={cart}
      order={order}
      discount={discount}
      isMobile={isMobile}
      showPayPal={!!showPayments?.showPayPal}
      skusNotAvailable={skusNotAvailable}
      storeCartIsLoading={storeCartIsLoading}
      setStoreCartIsLoading={setStoreCartIsLoading}
      promoTargetSkus={promoTargetSkus}
      setSkusNotAvailable={setSkusNotAvailable}
    />
  )
}

export default Carts
