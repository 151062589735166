import styled, { css } from 'styled-components'
import { colors, fonts } from '@constants/styles'

export const ProductListDiv = styled.div`
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 22px 16px;
    /* > div {
      margin-bottom: 45px;
    } */
  }
`

export const ActiveAddonsDiv = styled.div`
  display: grid;
  grid-template-columns: 100px 3fr 1fr;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    margin-left: 0;
  }
  @media only screen and (max-width: 639px) {
    font-size: 0.75em;
  }
`

export const InfoDiv = styled.div`
  display: grid;
  margin-left: 4em;
  margin-bottom: 1em;
  @media only screen and (max-width: 1024px) {
    text-align: center;
    margin: 0;
  }
`

export const PriceDiv = styled.span`
  min-width: 5em;
  @media only screen and (max-width: 1024px) {
    min-width: 5.3em;
  }
`

export const Plan = styled.span`
  font-size: 13px;

  @media only screen and (max-width: 639px) {
    white-space: nowrap;
  }
`

export const Quantity = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-weight: 600;
`

export const Sku = styled.span`
  font-size: 0.85em;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
  @media only screen and (max-width: 639px) {
    font-size: 0.9em;
    margin-top: 0.5em;
    font-weight: 300;
  }
`
export const Title = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  @media only screen and (max-width: 1024px) {
    margin-top: 15px;
    font-size: ${fonts.txtMedium};
  }
`

export const Img = styled.img`
  max-width: 100px;
  height: 60px;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    max-width: 150px;
    height: 75px;
  }
`

export const View = styled.div`
  ${({ currentState }) => css`
    display: none;
    color: ${colors.primary};
    text-decoration: underline;
    cursor: pointer;
    padding: ${currentState ? '25px 0 14px' : '0 0 14px'};
    margin: 0 auto;
    > svg {
      display: inline-block;
      fill: ${colors.primary};
      margin: 0 0 0 4px;
      height: 12px;
      transform: ${currentState ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
    @media only screen and (max-width: 1024px) {
      display: block;
    }
  `}
`
